<template>
  <DivisiManajemenForm mode="Tambah" module="Variable KPI"> </DivisiManajemenForm>
</template>

<script>
import DivisiManajemenForm from './form';

const DivisiManajemenAdd = {
  name: 'DivisiManajemenAdd',
  components: { DivisiManajemenForm },
};

export default DivisiManajemenAdd;
</script>
